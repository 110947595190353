import { ElMessage } from 'element-plus'
import { useFetch, useRequestHeaders } from 'nuxt/app'
import { removeLoginDataFn } from '@/utils/index'

type UrlType =
  | string
  | Request
  | Ref<string | Request>
  | (() => string | Request)

export interface RequestOptions {
  method?: any
  params?: any
  needBaseUrl?: any
  newHeaders?: any
}

const request = (url: UrlType, params: any, options: RequestOptions) => {
  return new Promise(async (resolve, reject) => {
    const headers = useRequestHeaders()
    // const headers = useRequestHeaders(['cookie']);
    const runtimeConfig = useRuntimeConfig().public
    const {
      method = ((options?.method || 'GET') as string).toUpperCase(),
      needBaseUrl = true,
      newHeaders
    } = options
    return await useFetch(url as string, {
      default: () => [],
      baseURL: needBaseUrl ? runtimeConfig.baseURL : '', // 不需要baseUrl的 needBaseUrl改成true
      method,
      headers,
      // lazy: true,
      params: method === 'POST' ? undefined : { ...params },
      body: method === 'POST' ? JSON.stringify(params) : undefined,
      onRequest ({ request, options }) {
        // Set the request headers
        options.headers = newHeaders ||
          options.headers || {
            'content-type': 'application/json',
            Authorization: `Bearer `
          }
      },
      onRequestError ({ request, options, error }) {
        ElMessage.closeAll()
        error && ElMessage.error('Sorry, The Data Request Failed')
        // Handle the request errors
        reject()
      },
      onResponse ({ request, response, options }) {
        const code = response._data && response._data.code
        console.log('code: ', code)
        if (+code === 401) {
          // 登录失效
          removeLoginDataFn()
        } else {
          resolve(response._data)
        }
      },
      onResponseError ({ request, response, options }) {
        console.log(
          '🚀 ~ file: MyRequest.ts:42 ~ onResponseError ~ request:',
          request
        )
        // Handle the response errors
        reject()
      }
    })
  })
}

export const useDefaultRequest = {
  get: (url: UrlType, params?: any, option?: RequestOptions) => {
    return request(url, params, { method: 'GET', ...option })
  },
  post: (url: UrlType, params?: any, option?: RequestOptions) => {
    return request(url, params, { method: 'POST', ...option })
  }
}
